Phoenix.browsingHistory = (() => {
  const storedBrowsingHistory =
    localStorage && Phoenix.config.BROWSING_HISTORY_KEY
      ? localStorage.getItem(Phoenix.config.BROWSING_HISTORY_KEY)
      : '';

  const browsingHistory = storedBrowsingHistory ? JSON.parse(storedBrowsingHistory) : [];
  const currentPath = window.location.href;
  const currentPathIndex = browsingHistory.indexOf(currentPath);

  // For relevant insights, we're not storing the same page twice, thus ignoring refreshed pages.
  if (currentPathIndex > -1) {
    browsingHistory.splice(currentPathIndex, 1);
  }

  // The current URL path becomes the last visited page.
  browsingHistory.push(currentPath);

  // To avoid exceeding storage limits, we will only store the last 3 visited URLs.
  if (browsingHistory.length > 3) {
    browsingHistory.shift();
  }

  if (localStorage && Phoenix.config.BROWSING_HISTORY_KEY) {
    localStorage.setItem(Phoenix.config.BROWSING_HISTORY_KEY, JSON.stringify(browsingHistory));
  }

  return browsingHistory;
})();
